(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("single-spa"), require("@landr/root-log"), require("@landr/root-app/init-helpers"), require("@landr/root-auth"));
	else if(typeof define === 'function' && define.amd)
		define(["single-spa", "@landr/root-log", "@landr/root-app/init-helpers", "@landr/root-auth"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("single-spa"), require("@landr/root-log"), require("@landr/root-app/init-helpers"), require("@landr/root-auth")) : factory(root["single-spa"], root["@landr/root-log"], root["@landr/root-app/init-helpers"], root["@landr/root-auth"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__971__, __WEBPACK_EXTERNAL_MODULE__657__, __WEBPACK_EXTERNAL_MODULE__163__, __WEBPACK_EXTERNAL_MODULE__271__) => {
return 